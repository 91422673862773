.overlay{
    position: absolute;
    top: 0;
    left: 0;
    /* Gray800 with a 60% opacity */
    background-color: var(--Gray900);
    opacity: 80%;
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

.container{
    background-color: var(--Gray800);
    box-shadow: 4px 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    scroll-behavior: smooth;
}

.closeSidebarBtn{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    margin-left: -16px;
    margin-top: -16px;
    background-color: var(--Gray800);
    padding: .5rem 1rem;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.446);
    border-radius: 3rem;
    transition: transform .2s ease-in-out;
    cursor: pointer;
}

.closeSidebarBtn:hover{
    transform: scale(1.1);
}

.closeSidebarBtn p{
    margin: 0;
    align-items: center;
}


.closeSidebarBtn p::before{
    content: "";
    display: block;
    background: url("../icons/x.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    fill: var(--Gray100);
    margin: -2px .5rem 0 0;
}

.profilePic{
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    background-image: none;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: top;
}

.socials{
    display: flex;
    flex-direction: row;
    padding: 0 1rem;
    margin-top: -1rem;
    column-gap: 1rem;
    margin-bottom: 2rem;
}

.socials .socialBtn{
    background-color: var(--Gray700);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    box-shadow: 0px 4px 4px 0px #00000040;
    transition: transform .2s ease-in-out;
    cursor: pointer;

}

.socials .socialBtn:hover{
    transform: scale(1.1);

}

.socialBtn svg{
    stroke: var(--Gray100);
    fill: none;
    stroke-width: 1.5;
    background-color: none;
    width: 1.5rem;
    height: 1.5rem;
}

.bio{
    font-size: 1.1rem;
    margin-bottom: 2rem;
}

hr{
    width: 100%;
    border: 1px solid var(--Gray700)
}

.skills{
    margin: 2rem 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: sticky;
    top: -2.5rem;
    background-color: var(--Gray800);
}

.skills .skillPills{
    display: flex;
    flex-direction: row;
    align-items:center;
    row-gap: .5rem;
    flex-wrap: wrap;
    column-gap: .5rem;
}

.skills .skillPills a{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: .25rem 1rem;
    border: 1px solid var(--Gray100);
    border-radius: 3rem;
    color: var(--Gray100);
    transition: all .2s ease-out;
}

.skills .skillPills a.active{
    border: 1px solid var(--Gray600);
    background-color: var(--Gray600)
}

.skillPills .all::before{
    content: "";
    display: block;
    background: url("../icons/list.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 .5rem 0 0;
}

.skillPills .des::before{
    content: "";
    display: block;
    background: url("../icons/pen-tool.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 .5rem 0 0;
}

.skillPills .dev::before{
    content: "";
    display: block;
    background: url("../icons/code.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 .5rem 0 0;
}

.skillPills .res::before{
    content: "";
    display: block;
    background: url("../icons/book-open.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 .5rem 0 0;
}

.skillPills .oth::before{
    content: "";
    display: block;
    background: url("../icons/tool.svg") no-repeat;
    background-size: 1.5rem;
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 .5rem 0 0;
}

.skillsResult{
    display: inline-block;
    hyphens: auto;
    padding-bottom: 2rem;
    line-height: 200%;
}

.skillsResult a{
    word-wrap: break-word;
    color: var(--Gray100);
}

.skillsResult a:not(:first-child)::before{
    content: "•";
    display: inline-block;
    margin: 0 1rem;
}

@media screen and  (max-width: 1024px) {

}
  
  @media screen and (max-width: 768px) {
    .container{

        padding: 24px;

    }
  }
  
  @media screen and (max-width: 480px) {

  }

