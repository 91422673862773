@font-face {
  font-family: "Inter";
  src: local('Inter-VariableFont_slnt_wght'),
  url('./assets/fonts/Inter-VariableFont_slnt_wght.ttf') format("truetype");
}

@font-face {
  font-family: "Syne";
  src: local('Syne-VariableFont_wght'),
  url('./assets/fonts/Syne-VariableFont_wght.ttf') format("truetype");
}

:root{
  --font-inter: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --font-syne: 'Syne', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;


  --white: #FFFFFF;
  --black: #000000;
  --Gray900: #1D1D1D;
  --Gray800: #3C3C3C;
  --Gray700: #4d4d4d;
  --Gray600: #6B6B6B;
  --Gray300: #B6B6B6;
  --Gray200: #D6D6D6;
  --Gray100: #ECECEC;
  --Purple_Light: #CCC7FD;
  --Purple_Medium: #958FDA;
  --Purple_Dark: #5952AA;
  --Light_Blue_Light: #8FD1DA;
  --Light_Blue_Medium: #32B1C2;
  --Light_Blue_Dark: #097D8D;
  --Blue_Light: #8FB1E4;
  --Blue_Medium: #6181F1;
  --Blue-Dark: #304BA8;
  --Pink_Light: #FCBAED;
  --Pink_Medium: #E971CE;
  --Pink_Dark: #A33E8C;
  --Red_Light: #FB9B9B;
  --Red_Medium: #FF6C6C;
  --Red_Dark: #9B2E2E;
  --Green_Light: #A7DA8F;
  --Green_Medium: #64C138;
  --Green_Dark: #387D18;
}



/* Home Screen */

.intro{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  min-height: 480px
}

.intro h1{
  width: 70%;
  max-width: 900px;
  color: var(--Gray600);
  text-align: center;
}

.intro a{
  color: var(--Gray100);
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
}

.intro .product-designer-link:before{
  content: "product designer";
  position: absolute;
  color: var(--Purple_Light);
  top: 1;
  left: 0;
  width: 0%;
  overflow: hidden;
  transition: all 0.5s;
  text-decoration: underline;
}

.intro .product-designer-link:hover:before{
  width: 100%;
  z-index: 9;
}

.intro .software-engineer-link:before{
  content: "software engineer";
  position: absolute;
  color: var(--Pink_Light);
  top: 1;
  left: 0;
  width: 0%;
  overflow: hidden;
  transition: all 0.5s;
  text-decoration: underline;
}

.intro .software-engineer-link:hover:before{
  width: 100%;
  z-index: 9;
}

.intro .musician-link:before{
  content: "musician";
  position: absolute;
  color: var(--Light_Blue_Light);
  top: 1;
  left: 0;
  width: 0%;
  overflow: hidden;
  transition: all 0.5s;
  text-decoration: underline;
}

.intro .musician-link:hover:before{
  width: 100%;
  z-index: 9;
}

.intro .explorer-link:before{
  content: "explorer";
  position: absolute;
  color: var(--Green_Light);
  top: 1;
  left: 0;
  width: 0%;
  overflow: hidden;
  transition: all 0.5s;
  text-decoration: underline;
}

.intro .explorer-link:hover:before{
  width: 100%;
  z-index: 9;
}

.container{
  padding: 0 4rem;
  max-width: 1920px;
  margin: 0 auto;
}

.projectList{
    display: grid; 
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: 1fr;
    margin-bottom: 2rem;
}

.aboutBubble{
  box-shadow: 2px 4px 12px 0px hsla(0, 0%, 0%, 0.8);
  display: none;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--Gray800);
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 999;
}

@media screen and  (max-width: 1024px) {
  .container{
    padding: 0 1rem;
  }

  .projectList{
  
    grid-gap: 1rem;
    grid-template-columns: repeat(2,1fr);
  }
}

@media screen and (max-width: 768px) {

  .aboutBubble{
    display: flex;
  }
}

@media screen and (max-width: 480px) {
  .projectList{
  
    grid-gap: 1rem;
    grid-template-columns: repeat(1,1fr);
  }

  .intro{
    height: 40vh;
    min-height: 240px;
  }

  .intro h1{
    font-size: 2rem;
  }
}