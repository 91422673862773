.computer {
    width: 100%;
    aspect-ratio: 2/.75;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

.computer .content {
    position: absolute;
    top: 11%;
    left: 50%;
    width: 68.75%;
    height: 115%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: cover;
    z-index: 15;
    z-index: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: black;
}

.computer .videoContent{
    
    position: absolute;
    top: 11%;
    left: 50%;
    width: 69%;
    height: 115%;
    aspect-ratio: 1.6/1;
    object-fit: fill;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-size: cover;
    z-index: 15;
    z-index: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;

}

.computer img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
}