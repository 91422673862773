.mainContainer{
    display: flex;
    flex-direction: row;
    width: calc(100% - 8rem);
    max-width: 1920px;
    align-items: center;
    justify-content: space-between;
    min-height: 20vh;
    margin: 0 auto 2rem auto;
    padding: 0 4rem;
}

.projCont{
    border: 1px solid var(--Gray300);
    padding: 1rem;
    border-radius: .5rem;
    width: 40%;
    height: 85px;
    transition: border .2s ease-in-out;
    cursor: pointer;
    overflow: hidden;
}

.projCont:hover{
    border: 1px solid var(--Gray100);
}

.flexHorz{
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    overflow: hidden;
}

.flipped{
    flex-direction: row-reverse !important;
}

.flipped p, .flipped h5{
    text-align: end !important;
}

.flexHorz span{
    overflow: hidden;
}

.projCont p{
    color: var(--Gray300)
}

.projCont h5{
    color: var(--Gray100);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.projCont img{
    width: 80px;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: .25rem;
}

.projCont video{
    width: 80px;
    object-fit: cover;
    aspect-ratio: 1/1;
    border-radius: .25rem;
}

@media screen and  (max-width: 1024px) {
   h5{
        font-size: 1.5rem;
    }

    .projCont{
        width: 40%;
    }
}
  
  @media screen and (max-width: 768px) {

    .mainContainer{
        flex-direction: column;
        row-gap: 2rem;
        justify-content: center;
    }

    h5{
        font-size: 1.5rem;
    }

    .projCont{
        width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .mainContainer{
        flex-direction: column;
        row-gap: 1rem;
        justify-content: center;
        padding: 0 2rem;
        margin: 0;
        width: calc(100% - 4rem);
    }
  }