.iphone {
  /* position: absolute; */
  width: 10%;
  position: relative;
  aspect-ratio: 109/250;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  z-index: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  overflow: hidden;
}
.iphone .content {
  /* position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  padding-top: 0%; */
  /* -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
  /* background-size: cover;
  z-index: 15;
  z-index: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; */

  position: absolute;
  margin-top: 19%;
  width: 88%;
  height: 84%;
  aspect-ratio: 9/19.9;
  border-radius: 5%;
  z-index: 15;
  z-index: 0;
  /* padding-left: 2%; */
  margin-left: 6%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  /* background-image: url('https://www.bebold.ch/img/home/presentation-anim.gif'); */
}

.iphone img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}