/* .link{
    flex: 1;
} */

.card{
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    background-color: var(--Gray700);
    border-radius: .5rem;
    text-decoration: none;
    overflow: hidden;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
    transition: all .2s ease-in-out;
}

.card:hover{
    transform: scale(1.025);
    box-shadow: 4px 4px 12px 4px rgba(0, 0, 0, 0.25);
}

.card .header{
    display: flex;
    max-height: 400px;
    width: 100%;
    height: 400px;
    overflow: hidden;

}

.card .header .left{
    flex: 16;
    align-items: center;
    position: relative;
}

.card .header .left .content{
    height: 100%;
    padding: 10% 5% 0 5%;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: -3rem;
}

.card .header .left .content .computerOnly{
    scale: 130%;
}


.card .header .right{
    flex: 6;
}


/* 

.card .header .left .computer{
    width: 185%;
}

.card .header .left .image{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    max-height: 320px;
}

.card .imageReversed{
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    max-height: 320px;
} */

.card .container{
    display: flex;
    flex-direction: column;
    row-gap: .5rem;
    padding: 1rem;
}

.card .container p{
    margin: 0;
}

.card .container .title{
    font-size: 1.5rem;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.card .container .subtitle{
    font-size: 1rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
           line-clamp: 4; 
   -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.card .lockedCont{
    position: absolute;
    top: 8px;
    padding: 6px 8px;
    background-color: hsla(0, 0%, 30%, 0.5);
    border-radius: .5rem;
    z-index: 9;
}

.card .lockedCont p{
    margin-bottom: 0;
}

.card .lockedCont p::before{
    content: "";
    display: block;
    background: url("../icons/lock.svg") no-repeat;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    float: left;
    fill: var(--Gray100);
    stroke: var(--Gray100);
    margin: 0 .5rem 0 0;
}

@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 768px) {


    .card .container .subtitle{
       -webkit-line-clamp: 3; /* number of lines to show */
               line-clamp: 3; 
    }


    .card .header{
        height: 60%;
    }

    .card .header .left .content{
        scale: .75;
    }
}

@media screen and (max-width: 480px) {
    .card .container .subtitle{
        -webkit-line-clamp: 4; /* number of lines to show */
                line-clamp: 4; 
     }
}