 
.tabContainer{
    padding: 0 6rem;
    
    position: sticky;
    top: 0;
    background-color: var(--Gray900);
    margin-bottom: 2rem;
    z-index: 999;
    /* background-color: green; */
  
}

.tabContainer ul{
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    max-width: 1920px;
    margin: 0 auto;
}

.tabContainer ul li{
    text-align: center;
    margin: 0;

}

.tabContainer a {
    cursor: pointer;
    display: inline-block;
    padding: 1rem 0;
    margin: 0;
    text-decoration: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 1rem 1.5rem;
    /* width: 140px; */
    color: var(--Gray300);
    transition: color .25s ease-in-out;

}

.tabContainer .all a:hover,  .tabContainer .about a:hover{
    color: var(--Gray100);
}

.tabContainer .pd a:hover{
    color: var(--Purple_Light);
}

.tabContainer .eng a:hover{
    color: var(--Pink_Light);
}

.tabContainer .mus a:hover{
    color: var(--Light_Blue_Light);
}

.tabContainer .exp a:hover{
    color: var(--Green_Light);
}

.active.all a{
    color: var(--Gray100) !important;
}

.active.pd a{
    color: var(--Purple_Light) !important;
}

.active.eng a{
    color: var(--Pink_Light) !important;
}

.active.mus a{
    color: var(--Light_Blue_Light) !important;
}

.active.exp a{
    color: var(--Green_Light) !important;
}

li.about{
    margin-left: auto !important;
}

.underline {
    position: absolute;

    height: 2px;
    transition: all .25s ease-in-out;
    bottom: 0;

}

@media screen and (max-width: 1024px) {
    .tabContainer{
        padding: 0 2rem;
      
    }

    .tabContainer a {
        padding: 1rem;
    
    }
}

@media screen and (max-width: 768px) {
    li.about{
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .tabContainer{
        padding: 1rem 1.25rem;

      
    }

    .tabContainer ul{
        justify-content: center;
    }

    .tabContainer a {
        padding: 1rem .8rem;
    }
}