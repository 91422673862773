:root{
    --font-inter: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    --font-syne: 'Syne', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
  
    --white: #FFFFFF;
    --black: #000000;
    --Gray900: #1D1D1D;
    --Gray800: #3C3C3C;
    --Gray700: #4d4d4d;
    --Gray600: #6B6B6B;
    --Gray300: #B6B6B6;
    --Gray200: #D6D6D6;
    --Gray100: #ECECEC;
    --Purple_Light: #CCC7FD;
    --Purple_Medium: #958FDA;
    --Purple_Dark: #5952AA;
    --Light_Blue_Light: #8FD1DA;
    --Light_Blue_Medium: #32B1C2;
    --Light_Blue_Dark: #097D8D;
    --Blue_Light: #8FB1E4;
    --Blue_Medium: #6181F1;
    --Blue-Dark: #304BA8;
    --Pink_Light: #FCBAED;
    --Pink_Medium: #E971CE;
    --Pink_Dark: #A33E8C;
    --Red_Light: #FB9B9B;
    --Red_Medium: #FF6C6C;
    --Red_Dark: #9B2E2E;
    --Green_Light: #A7DA8F;
    --Green_Medium: #64C138;
    --Green_Dark: #387D18;
  }

  /* html, body {
    overflow-x: hidden;
  } */
  body {
    position: relative
  }

  header{
    background-color: var(--Gray800);
    position: fixed;
    top: 0;
    height: 4.5rem;
    padding: 0 4rem;
    width: calc(100vw - 8rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 9999999;
  }

  .progress {
    position: fixed;
    top: 4.5rem;
    left: 0;
    width: 100%;
    height: 2px;
    transform-origin: 0 0;
    z-index: 9999999;
  }

  #toHomeBtn{
    font-weight: 500;
    color: var(--Gray100);
    padding: .75rem 1rem;
    border: 2px solid var(--Gray300) !important;
    border-radius: .25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border .2s ease-in-out;
  }

  #toHomeBtn:hover{
    border: 2px solid var(--Gray100) !important;
  }

  #viewPageBtn{
    font-weight: 500;
    cursor: pointer;
    color: var(--Gray100);
    background-color: var(--Purple_Dark);
    padding: .75rem 1rem;
    border-radius: .25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .2s ease-in-out;
  }

  #viewPageBtn:hover{
    background-color: #5048a8;
  }

  .container{
    max-width: 1920px;
    margin: 0 auto;
    padding: 0 10vw;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }

  .intro{
    /* background-color: #097D8D; */
    width: 100%;
    height: calc(100vh - 6rem);
    min-height: 40rem;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
  }

  .sevenSixEight{
    display: none;
  }


  .left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
  }

  .right{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    align-items: center;
  }

  .header{
    font-family: var(--font-inter);
    font-weight: 700;
    /* font-size: 64px; */
    /* line-height: 64px; */
    margin: 0 0 1rem 0;
  }

  .subtitle{
    font-family: var(--font-inter);
    font-weight: 400;
    /* font-size: 32px; */
    /* line-height: 1.rem; */
    color: var(--Gray600);
    margin: 0;
  }

  .intro .left .heroContainer{
    width: 100%;
    height: 450px;
    overflow-x: visible;
    margin-top: 2rem;
    position: relative;
  }

  /* .intro .left .heroContainer2{
    width: 100%;
    height: 100%;
    position: relative;
  } */


  .intro .left .heroContainer .phone{
    width: 250px;
    overflow-y: hidden;
    z-index: 9;
  }

  .intro .left .heroContainer .herobg{
    height: 70%;
    /* width: 120vw; */
    position: absolute;
    bottom: 0;
    /* left: -50vw; */
    background-color: hsla(0, 0%, 30%, 0.5);
    border-radius: 1rem;
    z-index: -99;
  }

  .meta{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    row-gap: 1.25rem;
    text-align: right;
  }

  .intro .right .meta .metaDataCont{
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    row-gap: .25rem;
  }

  .metaTitle{
    font-size: 1rem;
    color: var(--Gray600);
  }

  .metaData{
    font-size: 1rem;
    color: var(--Gray100);
  }

  .readMore{
    display: flex;
    flex-direction: column;
    flex: 0;
    width: 100%;
    padding: 2rem 0;
    align-items: center;
    text-align: center;
  }

  .quoteSection{
    display: grid;
    grid-gap: 6rem;
    grid-template-columns: 1fr 1fr;
    min-height: 70vh;
  }

  .content{
    /* background-color: #097D8D; */
    width: 100%;
    padding: 10vh 0;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
  }

  .quote{
    margin: 0;
    font-family: var(--font-syne);
    position: relative;
    width: 80%;
  }

  .quote::before{
    content: '"';
    position: absolute;
    font-family: var(--font-syne);
    font-size: 6rem;
    top: -.25rem;
    left: -2.25rem;
    transform: rotate(-20deg);
    font-style: normal;


  }

.loadingContainer{
    width: 100vw;
    height: calc(100vh - 6rem);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    padding: 6rem 10vw 0 10vw;
}

.passwordModal{
  margin: auto;
  max-width: 600px;
  width: 80%;
  background-color: var(--Gray800);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 2rem;
}

.passwordModal h3{
  font-family: var(--font-inter);
  font-weight: 600;
  margin: .5rem 0;
}


.inputContainer{
  display: flex; 
  column-gap: 1rem;
}

.inputTotal{
  display: flex;
  flex: 1;
  flex-direction: column; 
  row-gap: .5rem; 
  font-size: 1rem;
  color: var(--Gray300)
}

.input{
  height: 2rem;
  font-size: 1rem;
  background-color: var(--Gray800); 
  border: 1px solid var(--Gray300);
  color: var(--Gray100);
  padding: .5rem;
  border-radius: 4px;
}

.input:focus{
  outline: 2px solid var(--Gray600);
}

.btnContainer{
  display: flex; 
  column-gap: 1rem;
}

.errorMessage{
  color: var(--Red_Light)
}

.ldsEllipsis {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
}
.ldsEllipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: ldsEllipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: ldsEllipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: ldsEllipsis3 0.6s infinite;
}
@keyframes ldsEllipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes ldsEllipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes ldsEllipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
 
.shimmerContainer{
    background: var(--Gray800);
    width: 100%;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
}
  

.shimmerContainer .shimmer{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  
    background: linear-gradient(100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(121, 121, 121, 0.5) 50%,
    rgba(255,255,255,0) 80%);
  
    animation: shimmer 1.25s infinite linear;
}

@media screen and (max-width: 1024px) {
  .title{
    margin: 0;
  }

  .subtitle{
    font-size: 1.25rem;
    margin: -1rem 0 0 0;
  }

  .header{
    margin: 0 0 2rem 0;
  }
}


@media screen and (max-width: 768px) {
  .intro .right{
    display: none;
  }


  .sevenSixEight{
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 0 0;
    row-gap: .5rem;
  }

  .quoteSection{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 55vh;
   row-gap: 4rem;
  }
  .quote{
    width: 100%;
  }
  .quote::before{
    font-size: 4rem;
    top: -.75rem;
    left: -1rem;

  }

  header{
    padding: 0 2rem;
    width: calc(100vw - 4rem);
  }

  .intro{
    height: auto;
    padding-bottom: 4rem;
  }

  .intro .left .heroContainer{
    height: 50vh
  }

  .readMore{
    display: none;
  }


}

@media screen and (max-width: 480px) {
  .readMore{
    display: none;
  }

  .intro .left .heroContainer{
    margin-top: 2rem;
    /* height: 250px; */
  }

  .intro{
    height: auto;
    padding-bottom: 10vh;
  }

  .inputContainer{
    flex-direction: column;
    row-gap: .5rem;
  }

  .btnContainer{
    flex-direction: column;
    row-gap: 1rem;
  }
}

  
@keyframes shimmer{
    from {
        transform: translateX(-200%);
    }
    to{
        transform: translateX(200%);
    }
}