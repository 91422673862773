:root{
    --font-inter: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    --font-syne: 'Syne', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

    --white: #FFFFFF;
    --black: #000000;
    --Gray900: #1D1D1D;
    --Gray800: #3C3C3C;
    --Gray700: #4d4d4d;
    --Gray600: #6B6B6B;
    --Gray300: #B6B6B6;
    --Gray200: #D6D6D6;
    --Gray100: #ECECEC;
    --Purple_Light: #CCC7FD;
    --Purple_Medium: #958FDA;
    --Purple_Dark: #5952AA;
    --Light_Blue_Light: #8FD1DA;
    --Light_Blue_Medium: #32B1C2;
    --Light_Blue_Dark: #097D8D;
    --Blue_Light: #8FB1E4;
    --Blue_Medium: #6181F1;
    --Blue-Dark: #304BA8;
    --Pink_Light: #FCBAED;
    --Pink_Medium: #E971CE;
    --Pink_Dark: #A33E8C;
    --Red_Light: #FB9B9B;
    --Red_Medium: #FF6C6C;
    --Red_Dark: #9B2E2E;
    --Green_Light: #A7DA8F;
    --Green_Medium: #64C138;
    --Green_Dark: #387D18;
  }

.container{
    background-color: var(--Gray900);
    /* height: 48px; */
    padding: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -4px 24px -8px hsla(0, 0%, 0%, 0.5);

}

.container p{
    font-size: .8rem;
    text-align: center;
}

.heart{
    animation: float 1.5s infinite ease-in-out;
    -webkit-animation: float 1.5s infinite ease-in-out; /* Safari 4+ */
    -moz-animation: float 1.5s infinite ease-in-out; /* Fx 5+ */
    -o-animation: float 1.5s infinite ease-in-out; /* Opera 12+ */
    position: relative;
    margin-left: 5px;

    -webkit-user-select: none;
	/* Chrome/Safari */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* IE10+ */
	/* Rules below not implemented in browsers yet */
	-o-user-select: none;
	user-select: none;

    color: var(--Pink_Light)
}

@keyframes float {
    0% {top: 0px;}
    50% {top: -2px;}
    100% {top: 0px;}
  }

  @-webkit-keyframes float {
    0% {top: 0px;}
    50% {top: -2px;}
    100% {top: 0px;}
  }
  @-moz-keyframes float {
    0% {top: 0px;}
    50% {top: -2px;}
    100% {top: 0px;}
  }
  @-o-keyframes float {
    0% {top: 0px;}
    50% {top: -2px;}
    100% {top: 0px;}
  }